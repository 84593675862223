import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image"
import BrandedDivider from "../components/branded_divider"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {
  cardTitle,
  cardContent
} from "../css/index.module.css"
import TelephoneLink from "../components/telephone_link"
import PageBanner from "../components/page_banner"
import {SEO} from "../components/seo"

const AboutPage = () => {
  return (
    <Layout pageTitle="About">
      <PageBanner title="Experience Matters">
        <StaticImage
          placeholder="blurred"
          alt="EnginuityUSA - About"
          src="../images/engineering.jpg"
        />
      </PageBanner>

      <Container className="py-5">
        <Row className="py-3 no-gutters">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>Who We Are</h4>
            <p className={cardContent}>Enginuity Construction LLC roots trace back to 1994 when our principal obtained a Certified General
              Contractor’s License in Florida to provide services to a wide array of clients including industrial,
              construction materials, institutional and commercial.
            </p>
            <p className={cardContent}>Some of our early projects included large scale construction activities in the phosphate mines, bulk
              material processing plants, bulk material storage and load out facilities, pollution control and many
              others. In the last several years we have changed our emphasis to true design build of commercial and
              residential projects. Our experience in handling the daily challenges of a construction project, have
              uniquely prepared us to execute a timely and cost-effective project plan to the benefit of our clients.
            </p>
            <p className={cardContent}>Our Engineers have been appointed by Government Leaders to serve on commissions
              to
              develop regulations used
              in industry today. They have taught as adjunct instructors at multiple Universities. Their experience
              in engineering design and construction is important when planning and executing
              new facility construction, existing facility expansion and renovation. They have
              successfully completed more than 1000 projects from small to projects exceeding
              500 million dollars. Enginuity is currently licensed in the States of Florida, Georgia,
              Alabama, South Carolina, Mississippi, and Louisiana.
            </p>
            <p className={cardContent}>Enginuity Construction, LLC is proud to be a Veteran Owned Business.</p>
          </Col>
        </Row>

        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>Learn More</h4>
            <p className={cardContent}>Contact us in Tampa, FL, at <TelephoneLink/> to learn more about
              our company and our services.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = () => <SEO title={"Enginuity GC | About"} />

export default AboutPage
